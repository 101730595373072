import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './common/layout/layout.component';
import { PrivacyPolicyComponent } from './wow/privacy-policy/privacy-policy.component';

const appRoutes: Routes = [
    {
        path: '',
        loadChildren: () => import('./common/common.module').then(m => m.AppCommonModule),
    },
    {
        path: '',
        component: LayoutComponent,
        children: [
            {
                path: 'home',
                loadChildren: () => import('./wow/dashboard/dashboard.module').then(m => m.DashboardModule),
            },
            {
                path: 'feedback',
                loadChildren: () => import('./wow/feedback/feedback.module').then(m => m.FeedbackModule),
            },
            {
                path: 'keywords',
                loadChildren: () => import('./wow/keywords/keywords.module').then(m => m.KeywordsModule),
            },
            {
                path: 'master',
                loadChildren: () => import('./wow/master/master.module').then(m => m.MasterModule),
            },
            {
                path: 'driver',
                loadChildren: () => import('./wow/driver/driver.module').then(m => m.DriverModule),
            },
            {
                path: 'reports',
                loadChildren: () => import('./wow/report/report.module').then(m => m.ReportModule),
            },
            {
                path: 'usermanagement',
                loadChildren: () => import('./wow/user/user.module').then(m => m.UserModule),
            },
            {
                path: 'storemanagement',
                loadChildren: () => import('./wow/store/store.module').then(m => m.StoreModule),
            },
            {
                path: "leadmanagement", loadChildren: () => import('./wow/leads/leads.module').then(m => m.LeadsModule)
            },
            {
                path: 'actions',
                loadChildren: () => import('./wow/actions/actions.module').then(m => m.ActionsModule),
            },
            {
                path: 'notifications',
                loadChildren: () => import('./wow/notifications/notifications.module').then(m => m.NotificationsModule),
            }
        ]
    },
    { path: 'privacy-policy', component: PrivacyPolicyComponent },
    { path: '**', loadChildren: () => import('./common/common.module').then(m => m.AppCommonModule) }
];

export const routing = RouterModule.forRoot(appRoutes, {});
