import { Component, OnInit, Inject } from "@angular/core";
import { HttpService } from "../../services/http.service";
import { ModalService } from "../modal.service";
import { Router } from "@angular/router";
import { SharedService } from "../../services/shared.service";
import { LeadsService } from "../../../wow/leads/leads.service";
import { RejectleadComponent } from "../rejectlead/rejectlead.component";
import { AssignleadComponent } from "../assignlead/assignlead.component";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-view-lead",
  templateUrl: "./view-lead.component.html",
  styleUrls: ["./view-lead.component.scss"],
})
export class ViewLeadComponent implements OnInit {
  public logs = [{}];
  public reassignData = new Object();
  public obj = {
    role: "CTL",
    leadIdAndPincodes: {},
  };
  public counter: number;
  public leadDetails: any;
  content: any[] = new Array();
  constructor(
    public router: Router,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ViewLeadComponent>,
    public modalService: ModalService,
    public sharedService: SharedService,
    public leadService: LeadsService,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.counter = 0;
    this.getLeadData(this.data.leadId);
    this.getLogData(this.data.leadId);
    this.reassignData["leads"] = new Array(`this.data.leadId`);
  }
  closeDialog() {
    this.dialogRef.close();
  }
  getLeadData(id) {
    let leadId = id;
    this.sharedService.display(true);
    this.modalService.getLead(`lead/getbyid/${leadId}`).subscribe(
      (response) => {
        if (response.payload[0].lat_long)
          response.payload[0].lat_long = JSON.parse(
            response.payload[0].lat_long
          );
        this.leadDetails = response.payload[0];
        this.leadDetails["primary_business"] =
          this.modalService.getPrimaryBusiness(
            response.payload[0]["primary_business"]
          );
        this.leadDetails["location"] = this.modalService.getLocation(
          response.payload[0]["location"]
        );
        console.log(this.leadDetails, "-----");
        this.sharedService.display(false);
      },
      (err) => {
        this.sharedService.display(false);
      }
    );
  }
  getLogData(id) {
    this.sharedService.display(true);
    this.modalService.getLead(`lead/logs/${id}`).subscribe(
      (response) => {
        response.payload.map((ele) => {
          let exam = { message: ele["message"], created_at: ele["created_at"] };
          this.logs.push(exam);
        });
        for (let i = this.counter + 1; i < this.logs.length; i++) {
          this.content.push(this.logs[i]);
          if (i % 4 == 0) break;
        }
        this.counter += 4;
        this.sharedService.display(false);
      },
      (err) => {
        this.sharedService.display(false);
      }
    );
  }
  getMoreData(id) {
    for (let i = this.counter + 1; i < this.logs.length; i++) {
      this.content.push(this.logs[i]);
      if (i % 4 === 0) break;
    }
    this.counter += 4;
  }
  navigatePage(user) {
    this.dialogRef.close();
    this.router.navigate([`leadmanagement/leads/${user}`]);
  }
  reAssignLead() {
    this.obj.leadIdAndPincodes[this.leadDetails.id] = this.leadDetails.pincode;
    console.log(this.obj, "this.obj");
    const dialogRef = this.dialog.open(AssignleadComponent, {
      width: "400px",
      data: { reAssigned: this.obj.leadIdAndPincodes, tab: "Assigned" },
      panelClass: "my-custom-dialog-class-reject",
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) this.dialogRef.close("reassigned");
    });
  }
  assignLead() {
    this.sharedService.display(true);
    this.obj.leadIdAndPincodes[this.leadDetails.id] = this.leadDetails.pincode;
    this.leadService
      .assignLeads("lead/assign/reviewerteam", this.obj)
      .subscribe(
        (response) => {
          this.sharedService.display(false);
          this.snackBar.open("Lead(s) assigned successfully", "okay", {
            duration: 2000,
            verticalPosition: "top",
            horizontalPosition: "center",
          });
          this.dialogRef.close("assigned");
        },
        (err) => {
          console.log(err, "");
          this.sharedService.display(false);
          this.snackBar.open(err.error, "okay", {
            duration: 2000,
            verticalPosition: "top",
            horizontalPosition: "center",
          });
        }
      );
  }
  rejectLead() {
    const dialogRef = this.dialog.open(RejectleadComponent, {
      data: { rejected: this.leadDetails.id, tab: "Assigned" },
      panelClass: "my-custom-dialog-class-reject",
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) this.dialogRef.close("rejected");
    });
  }
  assignActionRequiredLead() {
    let data = [
      {
        leadId: this.leadDetails["id"],
        status: this.leadDetails["status"],
        pincode: this.leadDetails["pincode"],
      },
    ];
    this.sharedService.display(true);
    this.leadService.assignLeads("lead/action/required", data).subscribe(
      (response) => {
        this.sharedService.display(false);
        this.snackBar.open("Lead(s) assigned successfully", "okay", {
          duration: 2000,
          verticalPosition: "top",
          horizontalPosition: "center",
        });
      },
      (err) => {
        console.log(err, "");
        this.snackBar.open(err.error, "okay", {
          duration: 2000,
          verticalPosition: "top",
          horizontalPosition: "center",
        });
        this.sharedService.display(false);
      }
    );
  }
}
