import { DatePipe } from "@angular/common";
import { Component, EventEmitter, Inject, OnInit } from "@angular/core";
import moment from "moment";
import { SessionStorage } from "ngx-webstorage";
import {
  DateRangeOptions,
  GetDateRange,
  Stores,
} from "../../../wow/store/store";
import { StoresService } from "../../../wow/store/stores.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

declare let $: any;
@Component({
  selector: "amazon-download-filter",
  templateUrl: "./download-filter.component.html",
  styleUrls: ["./download-filter.component.scss"],
  providers: [DatePipe],
})
export class DownloadFilterComponent implements OnInit {
  onAdd = new EventEmitter();
  mydate = new EventEmitter();
  @SessionStorage("auth") public user: any;
  public dateobj = {};
  public startDate;
  public endDate;
  public dateRangeObj: GetDateRange = new GetDateRange();
  public priority: Stores = new Stores();
  public fromdate: any;
  public todate: any;
  public prioritize: any;
  public dropdown = true;
  public dateVal = true;
  public disabled = true;
  public clickedImage = false;
  public dropdownvalue: any;
  public obj;
  public region;
  public egyptobj;
  public dateOptions: DateRangeOptions = new DateRangeOptions();
  startDates: any = new Date();
  endDates: any = new Date();
  options = [
    { value: "1", viewValue: "Download Prioritized Stores" },
    { value: "2", viewValue: "Download All Stores" },
  ];
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<DownloadFilterComponent>,
    private datePipe: DatePipe,
    private storeService: StoresService
  ) {
    // this.pickerConfig.skipCSS = false;
  }

  ngOnInit() {
    this.region = this.user.userDetails.region;
    this.dateobj = this.storeService.getDate();
    this.dateRangeObj = new GetDateRange(this.storeService.getDate());
    console.log(this.dateRangeObj);
    if (Object.keys(this.dateobj).length) {
      this.dateRangeObj.startdate =
        this.dateobj["startdate"] !== ""
          ? this.dateobj["startdate"]
          : this.dateRangeObj.startdate;
      this.dateRangeObj.enddate =
        this.dateobj["enddate"] !== ""
          ? this.dateobj["enddate"]
          : this.dateRangeObj.enddate;
      console.log("start date", this.dateobj["startdate"]);
    } else {
      if (!this.dateRangeObj.startdate) {
        this.dateRangeObj.startdate = this.datePipe.transform(
          Date.now(),
          "yyyy-MM-dd"
        );
      }

      if (!this.dateRangeObj.enddate) {
        this.dateRangeObj.enddate = this.datePipe.transform(
          Date.now(),
          "yyyy-MM-dd"
        );
      }
    }
    if (this.dateRangeObj.startdate !== "") {
      this.dateRangeObj.startdate = this.datePipe.transform(
        this.dateRangeObj.startdate,
        "yyyy-MM-dd"
      );
      this.dateRangeObj.enddate = this.datePipe.transform(
        this.dateRangeObj.enddate,
        "yyyy-MM-dd"
      );
    }
    console.log(
      "staartttt",
      this.dateRangeObj.startdate,
      this.dateRangeObj.enddate
    );

    this.fromdate = new Date(this.dateRangeObj.startdate);
    this.todate = new Date(this.dateRangeObj.enddate);
  }
  imageClicked() {
    this.clickedImage = true;
    this.dialogRef.close();
  }
  setInput() {
    $(".input-mini").attr("readonly", true);
  }
  changed(val) {
    console.log("value is", val);
    if (val == "Download Prioritized Stores") {
      console.log("1st");
      this.prioritize = true;
      this.dropdown = false;
      if (!this.dropdown) {
        this.disabled = false;
      }
    } else {
      console.log("2nd");
      this.prioritize = false;
      this.dropdown = false;
      if (!this.dropdown) {
        this.disabled = false;
      }
    }
  }
  convertDateFormat(inputDate, type) {
    const dateObject = new Date(inputDate);

    const year = dateObject.getFullYear();
    const month = ("0" + (dateObject.getMonth() + 1)).slice(-2); // Adding 1 because months are zero-based
    const day = ("0" + dateObject.getDate()).slice(-2);
    if (type == "start") {
      const formattedDate = `${year}-${month}-${day}`;
      return formattedDate;
    }
    if (type == "end") {
      const formattedDate = `${year}-${month}-${day}`;
      return formattedDate;
    }
  }
  startDateChange(event) {
    let startDate = this.convertDateFormat(event.target.value, "start");
    this.dateRangeObj.startdate = startDate;
    this.startDate = startDate;
  }
  endDateChange(event) {
    if (event.target.value) {
      let endDate = this.convertDateFormat(event.target.value, "end");
      this.dateRangeObj.enddate = endDate;
      this.endDate = endDate;
      this.downloadPriority(endDate);
    } else {
      const currentDate = new Date();
      const formattedDate = this.datePipe.transform(currentDate, "yyyy-MM-dd");
      this.dateRangeObj.enddate = formattedDate;
      this.endDate = formattedDate;
      this.downloadPriority(formattedDate);
    }
  }

  downloadPriority(event: any) {
    this.dateVal = false;
    if (!this.dropdown) {
      this.disabled = false;
    }

    if (event) {
      this.dateVal = false;
    }
    if (event !== "") {
      // this.dateRangeObj.startdate = this.datePipe.transform(
      //   event.picker.startDate._d,
      //   "yyyy-MM-dd"
      // );
      // this.dateRangeObj.enddate = this.datePipe.transform(
      //   event.picker.endDate._d,
      //   "yyyy-MM-dd"
      // );
    }
    this.storeService.setDate(
      this.dateRangeObj.startdate,
      this.dateRangeObj.enddate
    );

    console.log(this.dateRangeObj.startdate, this.dateRangeObj.enddate);
    this.storeService.myValue = this.dateRangeObj;
  }
  downloadCSV() {
    this.egyptobj = {
      startdate: this.startDate,
      enddate: this.endDate,
    };

    this.obj = {
      priority: this.prioritize,
      startdate: this.startDate,
      enddate: this.endDate,
    };
    this.onAdd.emit(this.obj);
  }
  downloadEgyptCSV() {
    this.egyptobj = {
      startdate: this.startDate,
      enddate: this.endDate,
    };

    this.obj = {
      priority: this.prioritize,
      startdate: this.startDate,
      enddate: this.endDate,
    };
    console.log("here val", this.egyptobj);

    this.onAdd.emit(this.egyptobj);
  }
}
