import { BreakpointObserver } from "@angular/cdk/layout";
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { SessionStorage } from "ngx-webstorage";
import { AppCommonService } from "../app-common.service";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import {
  myInsertRemoveTrigger,
  onSideNavChange,
} from "../../utils/animations/animations";
import { MatSnackBar } from "@angular/material/snack-bar";
@Component({
  // tslint:disable-next-line:component-selector
  selector: "amazon-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
  animations: [myInsertRemoveTrigger, onSideNavChange],
})
export class SidebarComponent implements OnInit {
  faWindowClose = faWindowClose;
  collapsed = true;
  collapse = true;
  public role;
  public region;
  @Output() collap = new EventEmitter<boolean>();
  toggleCollapse(): void {
    this.collapsed = !this.collapsed;
    console.log("entered");
    console.log(this.collapsed);
    this.collap.emit(this.collapsed);
  }
  collapsee() {
    this.collapse = false;
  }
  @SessionStorage("auth") public user: any;
  public modules: any = [];
  public myModules: any = [];

  constructor(
    private commonService: AppCommonService,
    private router: Router,
    private snackBar: MatSnackBar,
    private observer: BreakpointObserver
  ) {}

  ngOnInit() {
    this.region = this.user.userDetails.region;
    if (this.user && this.user.userDetails) {
      this.getModules();
    }
    // this.region=this.user.userDetails.region;
    this.role = this.user.userDetails.role;
    console.log(this.user);
  }

  // get all modules based on login
  getModules() {
    this.commonService.login("user/getUserModules", {}).subscribe(
      (response) => {
        if (response.success && response.payload) {

          console.log(response.payload, "check response");
          response.payload.forEach((element) => {
            if (element.modulename === "Dashboard") {
              element.routeLink = "/home/dashboard";
              element.identifier = "home";
            } else if (element.modulename === "Manage Feedback") {
              element.routeLink = "/feedback/viewfeedback";
              element.identifier = "feedback";
            } else if (element.modulename === "Keywords") {
              element.routeLink = "/keywords/manage-keyword";
              element.identifier = "keywords";
            } else if (element.modulename === "Manage Master") {
              if (this.region == "Egypt" || this.role == "VM") {
                element.routeLink = "/master/roles/viewroles";
              } else {
                element.routeLink = "/master/category/viewmaster";
              }
              element.identifier = "master";
            } else if (element.modulename === "Manage User") {
              // if(this.role == 'VM'){
              //   element.modulename = 'Manage Driver'
              //   // usermanagement/listDrivers
              //   element.routeLink = '/usermanagement/listDrivers';
              //   element.identifier = 'usermanagement';
              // }
              // else{
              element.routeLink = "/usermanagement/listusers";
              element.identifier = "usermanagement";
              // }
            } else if (element.modulename === "Van Drivers") {
              element.modulename = "Manage Van Driver";
              if (this.role == "VM") {
                element.routeLink = "/driver/driver-list/viewdriver";
              } else {
                element.routeLink = "/driver/user-list/viewuser";
              }
              element.identifier = "driver";
            } else if (element.modulename === "Report") {
              element.routeLink = "/reports/dailyreports";
              element.identifier = "reports";
            } else if (element.modulename === "Manage Station/Stores") {
              element.routeLink = "/storemanagement/stores/managestores";
              element.identifier = "storemanagement";
            } else if (element.modulename === "Manage Flags/Actions") {
              element.routeLink = "/actions/all";
              element.identifier = "actions";
            } else if (element.modulename === "Manage Notifications") {
              element.routeLink = "/notifications/manageNotifications";
              element.identifier = "notifications";
            } else if (element.modulename === "Leads") {
              element.routeLink = "/leadmanagement";
              element.identifier = "leadmanagement";
            }
          });
          if (
            (response.payload &&
              response.payload.length > 0 &&
              this.region == "Egypt") ||
            this.role == "VM"
          ) {
            response.payload.filter((val) => {
              if (
                !(
                  val.modulename == "Manage Feedback" ||
                  val.modulename == "Keywords" ||
                  val.modulename == "Manage Notifications" ||
                  val.modulename == "Manage Flags/Actions"
                )
              ) {
                this.myModules.push(val);
              }
            });
            this.modules = this.myModules;
          } else if (
            response.payload &&
            response.payload.length > 0 &&
            this.region == "India"
          ) {
            const removed = response.payload.splice(1, 1)[0];
            response.payload.splice(5, 0, removed);
            this.modules = response.payload;
          }
        } else {
          this.snackBar.open(
            response.error && response.error.message
              ? response.error.message
              : window["serverError"],
            "okay",
            window["snackBarConfig"]
          );
        }
        this.modules =this.modules.sort((a, b) => a.order - b.order);

      },
      (err) => {
        this.snackBar.open(
          err.error && err.error.message
            ? err.error.message
            : window["serverError"],
          "okay",
          window["snackBarConfig"]
        );
      }
    );
  }

  // highlight the sidemenu
  checkActiveRoute(identifier) {
    const active = this.router.url.indexOf(identifier) === -1 ? false : true;
    return active;
  }
}
