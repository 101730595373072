import { Injectable } from "@angular/core";

@Injectable()
export class LanguageService {
  private language: string = "en";
  readonly supportedLanguages: string[] = ["en", "fr", "de"];

  constructor() {
    this.init(navigator.language.split("-")[0]);
  }

  init(lang: string): void {
    if (this.supportedLanguages.indexOf(lang) > -1) {
      this.language = lang;
    }
  }

  getLanguage(): any {
    return this.language;
  }

  getsupportedLanguages(): any[] {
    return this.supportedLanguages;
  }
}
