import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Observable";
import "rxjs/add/operator/catch";
import "rxjs/add/operator/map";
import { HttpService } from "../../utils/services/http.service";
import { ErrorService } from "../../utils/services/error.service";
import { throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";

@Injectable()
export class StoresService {
  public myValue;
  private daterangeFilter = {};
  constructor(
    private httpService: HttpService,
    private errService: ErrorService
  ) {}

  getStores(url, query): Observable<any> {
    return this.httpService
      .post(url, query)
      .map((res: Response) => res)
      .catch((error: any) => {
        return this.errorHandler(error);
      });
  }
  setDate(startdate, enddate) {
    this.daterangeFilter["startdate"] = startdate;
    this.daterangeFilter["enddate"] = enddate;
  }

  getDate() {
    return this.daterangeFilter;
  }
  getStations(url): Observable<any> {
    return this.httpService
      .get(url)
      .map((res: Response) => res)
      .catch((error: any) => {
        return this.errorHandler(error);
      });
  }

  updateStores(url, query): Observable<any> {
    return this.httpService
      .put(url, query)
      .map((res: Response) => res)
      .catch((error: any) => {
        return this.errorHandler(error);
      });
  }

  createStores(url: any, data: any): Observable<any> {
    return this.httpService.postApi(url, data).pipe(
      map((res: Response) => {
        return res.hasOwnProperty("body") ? res.body : res;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  downloadStores(query): Observable<any> {
    return this.httpService
      .fileDownloadGet(query)
      .map((res: Response) => {
        console.log(res);
        return res;
      })
      .catch((error: any) => {
        return this.errorHandler(error);
      });
  }
  downloadPriorityStores(query, data): Observable<any> {
    return this.httpService
      .fileDownloadPost(query, data)
      .map((res: Response) => {
        console.log(res);
        return res;
      })
      .catch((error: any) => {
        return this.errorHandler(error);
      });
  }
  downloadWithData(query, data): Observable<any> {
    return this.httpService
      .post(query, data)
      .map((res: Response) => res)
      .catch((error: any) => {
        return this.errorHandler(error);
      });
  }

  deleteStore(url: any, data): Observable<any> {
    return this.httpService
      .post(url, data)
      .map((res: Response) => res)
      .catch((error: any) => {
        return this.errorHandler(error);
      });
  }

  post(url: any, data: any): Observable<any> {
    return this.httpService
      .post(url, data)
      .map((res: Response) => res)
      .catch((error: any) => {
        return this.errorHandler(error);
      });
  }

  errorHandler(error: any) {
    this.errService.handleError(error);
    return throwError(error.error);
  }
}
