import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { SessionStorage } from 'ngx-webstorage';
import { SharedService } from './utils/services/shared.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'amazon-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  @SessionStorage('auth') public user;
  public loading = true;

  constructor(
    public router: Router,
    public sharedService: SharedService,
    private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit() {
    // if (!this.user) {
    //   this.router.navigateByUrl('/login');
    // }

    this.sharedService.status.subscribe((val: boolean) => {
      this.loading = val;
      this.changeDetectorRef.detectChanges();
    });
  }
}
