export class Login {
    public userid: string;
    public password: string;
    public platform: string;
    constructor(data: any = {}) {
        this.userid = data.userid || '';
        this.password = data.password || '';
        this.platform = 'web';
    }
}

export class Auth {
    public userDetails: User;
    public token: string;
    public tokenType: string;
    public expires: number;
    constructor(d: any = {}) {
        this.userDetails = d.user ? new User(d.user) : new User();
        this.token = d.token || ''
        this.tokenType = d.tokenType || '';
        this.expires = d.expires || 0;
    }
}

export class User {
    public deleted: boolean
    public name: string
    public passwordreset: boolean
    public role: string
    public updateddate: any
    public userid: string
    public region:string
    constructor(d: any = {}) {
        this.deleted = d.deleted || false;
        this.name = d.name || '';
        this.passwordreset = d.passwordreset || null;
        this.role = d.role || '';
        this.updateddate = d.updateddate ? new Date(d.updateddate) : null;
        this.userid = d.userid || '';
        this.region=d.region;
    }
}

export class UpdateSession {
    public token: string;
    public type: string;
    public userid: string;
    public platform: string;
    public device: string;
    public imei: string;
    public location: Location;
    public ipaddress: string;
    constructor(d: any = {}) {
        this.token = d.token || '';
        this.type = d.type || '';
        this.userid = d.userid || '';
        this.platform = d.platform || 'web';
        this.device = d.device || window.navigator.userAgent;
        this.imei = d.imei || '';
        this.location = d.location ? new Location(d.location) : new Location();
        this.ipaddress = d.ipaddress || '';
    }
}

export class Location {
    public type: string;
    public coordinates: Array<any> = [];
    constructor(d: any = {}) {
        this.type = d.type || 'point';
        this.coordinates = [];
        navigator.geolocation.getCurrentPosition((position) => {
            this.coordinates.push(position.coords.latitude, position.coords.longitude)
        });
    }
}

