import "rxjs/add/operator/catch";
import "rxjs/add/operator/map";

import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Observable";

import { ErrorService } from "../utils/services/error.service";
import { HttpService } from "../utils/services/http.service";
import { throwError } from "rxjs";

@Injectable()
export class AppCommonService {
  constructor(
    private httpService: HttpService,
    private errService: ErrorService
  ) {}
  login(url: any, data: any): Observable<any> {
    return this.httpService
      .post(url, data)
      .map((res: Response) => res)
      .catch((error: any) => {
        return this.errorHandler(error);
      });
  }
  update(url: any, data: any): Observable<any> {
    return this.httpService
      .put(url, data)
      .map((res: Response) => res)
      .catch((error: any) => {
        return this.errorHandler(error);
      });
  }
  getAddress(url: any): Observable<any> {
    return this.httpService
      .getIP(url)
      .map((res: Response) => res)
      .catch((error: any) => {
        return this.errorHandler(error);
      });
  }
  errorHandler(error: any) {
    this.errService.handleError(error);
    return throwError(error.error);
  }
}
