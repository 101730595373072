import { Component, OnInit } from "@angular/core";
import { ChangePassword } from "./changepassword";
import { Router, ActivatedRoute } from "@angular/router";
import { AppCommonService } from "../app-common.service";
import { SessionStorage } from "ngx-webstorage";
import { MatSnackBar } from "@angular/material/snack-bar";
@Component({
  selector: "amazon-changepassword",
  templateUrl: "./changepassword.component.html",
  styleUrls: ["./changepassword.component.scss"],
})
export class ChangepasswordComponent implements OnInit {
  public user: ChangePassword = new ChangePassword();
  public sign: string;
  public showPassword = false;
  @SessionStorage("auth") public users: any;
  public passwordRegExp =
    "^(?=.{8,})(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+*!=]).*$";
  // public strength = { "errors": [], "strong": true };

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private commonService: AppCommonService
  ) {}

  ngOnInit() {
    if (this.activatedRoute.queryParams["value"].signature) {
      this.sign = this.activatedRoute.queryParams["value"].signature;
    } else {
      this.router.navigateByUrl("");
    }
  }
  savePassword() {
    this.user.signature = this.sign;

    this.commonService.login("changepassword", this.user).subscribe(
      (response) => {
        if (response.success) {
          this.router.navigate(["login"]);
          this.snackBar.open(
            "Password changed successfully",
            "okay",
            window["snackBarConfig"]
          );
        } else {
          this.snackBar.open(
            response.error && response.error.message
              ? response.error.message
              : window["serverError"],
            "okay",
            window["snackBarConfig"]
          );
        }
      },
      (er) => {
        this.snackBar.open(
          er.error && er.error.message
            ? er.error.message
            : window["serverError"],
          "okay",
          window["snackBarConfig"]
        );
      }
    );
  }

  /* toggle password */
  toggleInputType() {
    this.showPassword = !this.showPassword;
  }
}
