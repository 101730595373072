import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SessionStorage } from 'ngx-webstorage';
import { HttpService } from '../../utils';
import { AppCommonService } from '../app-common.service';
import { Auth } from '../login/login';

@Component({
  selector: 'amazon-delete-user',
  templateUrl: './delete-user.component.html',
  styleUrls: ['./delete-user.component.scss']
})
export class DeleteUserComponent implements OnInit {
 
  public storage;
  public userDetails;
  public token;
  public deleted=false;
  constructor(private httpService:HttpService,
    private router:Router) { 
      this.storage=sessionStorage.getItem("ng2-webstorage|auth")
      if(!this.storage){
        this.router.navigate(['/login'])
      }
    }

  ngOnInit() {

    console.log(this.storage);
    this.userDetails=JSON.parse(this.storage).userDetails;    

  }
  deleteUser(){
    this.httpService.delete(`/user/${this.userDetails.userid}`).subscribe(res=>{
      console.log(res);
      
      if(res.status==200){ 
      this.deleted=true; 
      }
    })
  }
  navigate(){
    this.router.navigate(["/login"]);
  }
}
