import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, tap, finalize, map } from "rxjs/operators";
import { SessionStorage } from "ngx-webstorage";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class HttpService {
  @SessionStorage("auth") public authToken: any;
  public serverError: Observable<HttpErrorResponse>;

  constructor(private http: HttpClient) {
    this.serverError = new Observable();
  }

  request(url: string, options?: any): Observable<any> {
    return this.http.request(url, options);
  }

  get(url: string, options?: any): Observable<any> {
    this.requestInterceptor();
    return this.http
      .get(this.getFullUrl(url), this.requestOptions(options))
      .pipe(
        catchError((err) => this.onCatch(err)),
        tap((res: any) => this.onSubscribeSuccess(res)),
        finalize(() => this.onFinally())
      );
  }

  ihsget(url: string, options?: any): Observable<any> {
    this.requestInterceptor();
    return this.http
      .get(this.getIhsFullUrl(url), this.requestOptions(options))
      .pipe(
        catchError((err) => this.onCatch(err)),
        tap((res: any) => this.onSubscribeSuccess(res)),
        finalize(() => this.onFinally())
      );
  }

  getIP(url: string, options?: any): Observable<any> {
    this.requestInterceptor();
    return this.http.get(url, this.requestOptions(options)).pipe(
      catchError((err) => this.onCatch(err)),
      tap((res: any) => this.onSubscribeSuccess(res)),
      finalize(() => this.onFinally())
    );
  }

  getLocal(url: string, options?: any): Observable<any> {
    return this.http.get(url, options);
  }

  /**
   * Performs a request with `post` http method.
   * @param url
   * @param body
   * @param options
   * @returns {Observable<>}
   */
  post(url: string, body: any, options?: any): Observable<any> {
    this.requestInterceptor();
    return this.http
      .post(this.getFullUrl(url), body, this.requestOptions(options))
      .pipe(
        catchError((err) => {
          return this.onCatch(err);
        }),
        tap(
          (res: Response) => {
            this.onSubscribeSuccess(res);
          },
          (error: any) => {
            this.onSubscribeError(error);
          }
        ),
        finalize(() => {
          this.onFinally();
        })
      );
  }

  getHeaders(addToken?: boolean) {
    let headers: HttpHeaders = new HttpHeaders();
    if (
      this.authToken &&
      this.authToken.tokenType &&
      this.authToken.token &&
      addToken
    ) {
      headers = headers.append(
        "x-access-token",
        this.authToken.tokenType + " " + this.authToken.token
      );
    }
    return headers;
  }
  handleError(error: any): Observable<any> {
    return throwError(error);
  }

  postApi(api: any, data: any): Observable<any> {
    return this.http
      .post(this.getFullUrl(api), data, {
        headers: this.getHeaders(true),
        observe: "response",
      })
      .pipe(
        map((response) => response),
        catchError(this.handleError)
      );
  }

  fileDownloadGet(url: string, options?: any): Observable<any> {
    this.requestInterceptor();
    return this.http
      .get(this.getFullUrl(url), this.postrequestOptions(options, true))
      .pipe(
        catchError((err) => this.onCatch(err)),
        tap((res: any) => res),
        finalize(() => this.onFinally())
      );
  }
  fileDownloadPost(url: string, body: any, options?: any): Observable<any> {
    this.requestInterceptor();
    return this.http
      .post(this.getFullUrl(url), body, this.postrequestOptions(options, true))
      .pipe(
        catchError((err) => {
          return this.onCatch(err);
        }),
        tap(
          (res: Response) => {
            res;
            // if (options && options.responseType === "blob") {
            //   this.downloadFile(res); // Handle file download if the response type is 'blob'
            // } else {
            //   this.onSubscribeSuccess(res);
            // }
          },
          (error: any) => {
            this.onSubscribeError(error);
          }
        ),
        finalize(() => {
          this.onFinally();
        })
      );
  }

  ihspost(url: string, body: any, options?: any): Observable<any> {
    this.requestInterceptor();
    return this.http
      .post(this.getIhsFullUrl(url), body, this.requestOptions(options))
      .pipe(
        catchError((err) => this.onCatch(err)),
        tap((res: any) => this.onSubscribeSuccess(res)),
        finalize(() => this.onFinally())
      );
  }

  upload(url: string, body: any, options?: any): Observable<any> {
    this.requestInterceptor();
    return this.http
      .post(this.getIhsFullUrl(url), body, this.requestOptions(options))
      .pipe(
        catchError((err) => this.onCatch(err)),
        tap((res: any) => this.onSubscribeSuccess(res)),
        finalize(() => this.onFinally())
      );
  }

  put(url: string, body: any, options?: any): Observable<any> {
    this.requestInterceptor();
    return this.http
      .put(this.getFullUrl(url), body, this.requestOptions(options))
      .pipe(
        catchError((err) => this.onCatch(err)),
        tap((res: any) => this.onSubscribeSuccess(res)),
        finalize(() => this.onFinally())
      );
  }

  ihsput(url: string, body: any, options?: any): Observable<any> {
    this.requestInterceptor();
    return this.http
      .put(this.getIhsFullUrl(url), body, this.requestOptions(options))
      .pipe(
        catchError((err) => this.onCatch(err)),
        tap((res: any) => this.onSubscribeSuccess(res)),
        finalize(() => this.onFinally())
      );
  }

  delete(url: string, options?: any): Observable<any> {
    this.requestInterceptor();
    return this.http
      .delete(this.getFullUrl(url), this.requestOptions(options))
      .pipe(
        catchError((err) => this.onCatch(err)),
        tap((res: any) => this.onSubscribeSuccess(res)),
        finalize(() => this.onFinally())
      );
  }

  ihsdelete(url: string, options?: any): Observable<any> {
    this.requestInterceptor();
    return this.http
      .delete(this.getIhsFullUrl(url), this.requestOptions(options))
      .pipe(
        catchError((err) => this.onCatch(err)),
        tap((res: any) => this.onSubscribeSuccess(res)),
        finalize(() => this.onFinally())
      );
  }

  private postrequestOptions(options?: any, boolean?): any {
    if (!options) {
      options = {};
    }
    let headers;

    if (!options.headers) {
      options.headers = new HttpHeaders({ "Content-Type": "application/json" });
      headers = options.headers;
    }

    if (this.authToken) {
      options.headers = options.headers.set(
        "x-access-token",
        `${this.authToken.tokenType} ${this.authToken.token}`
      );
    }
    if (boolean) {
      return { headers, responseType: "blob", ...options };
    }

    options.headers = options.headers.set("application", "wow");

    return options;
  }

  private requestOptions(options?: any): any {
    if (!options) {
      options = {};
    }

    if (!options.headers) {
      options.headers = new HttpHeaders({ "Content-Type": "application/json" });
    }

    if (this.authToken) {
      options.headers = options.headers.set(
        "x-access-token",
        `${this.authToken.tokenType} ${this.authToken.token}`
      );
    }

    options.headers = options.headers.set("application", "wow");

    return options;
  }

  /**
   * Build API url.
   * @param url
   * @returns {string}
   */
  private getFullUrl(url: string): string {
    return environment.baseUrl + url;
  }

  private getIhsFullUrl(url: string): string {
    return environment.ihsBaseUrl + url;
  }

  private requestInterceptor(): void {
    // Add logic for request interception if needed
  }

  private onCatch(error: HttpErrorResponse): Observable<any> {
    return throwError(error);
  }

  /**
   * onSubscribeSuccess
   * @param res
   */
  private onSubscribeSuccess(res: Response): void {}

  /**
   * onSubscribeError
   * @param error
   */
  private onSubscribeError(error: any): void {
    // this.serverError.emit(error);
  }

  /**
   * onFinally
   */
  private onFinally(): void {
    this.responseInterceptor();
  }
  /**
   * Response interceptor.
   */
  private responseInterceptor(): void {}
}
