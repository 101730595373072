import { Injectable } from "@angular/core";
import { HttpService } from "../services/http.service";
import { Observable, throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { ErrorService } from "../services/error.service";
import { SharedService } from "../services/shared.service";

@Injectable()
export class ModalService {
  primaryBusines = [
    { id: 1, primary_business: "Daily needs" },
    { id: 2, primary_business: "Grocery" },
    { id: 3, primary_business: "Repairs & Services" },
    { id: 4, primary_business: "Telecom" },
    { id: 5, primary_business: "Stationery" },
    { id: 6, primary_business: "Eat & Dine / Food" },
    { id: 7, primary_business: "Consumer goods" },
    { id: 8, primary_business: "Pharmacy" },
    { id: 9, primary_business: "Chain store" },
    { id: 10, primary_business: "Real estate" },
    { id: 11, primary_business: "Electrical, hardware and paints" },
    { id: 12, primary_business: "Apparel / fashion" },
    { id: 13, primary_business: "Others" },
  ];
  location = [
    { id: 1, location: "Bangalore" },
    { id: 2, location: "Delhi" },
    { id: 3, location: "Mumbai" },
    { id: 4, location: "Pune" },
    { id: 5, location: "Hyderabad" },
    { id: 6, location: "Ahmedabad" },
    { id: 7, location: "Chennai" },
    { id: 8, location: "Kolkata" },
    { id: 9, location: "Noida" },
    { id: 10, location: "Jaipur" },
    { id: 11, location: "Lucknow" },
    { id: 12, location: "Visakhapatnam" },
    { id: 13, location: "Others" },
  ];
  constructor(
    public httpService: HttpService,
    private errService: ErrorService,
    sharedService: SharedService
  ) {}
  getLead(query): Observable<any> {
    return this.httpService
      .ihsget(query)
      .map((res: Response) => res)
      .catch((error: any) => {
        return this.errorHandler(error);
      });
  }
  getReasons(query): Observable<any> {
    return this.httpService
      .ihsget(query)
      .map((res: Response) => res)
      .catch((error: any) => {
        return this.errorHandler(error);
      });
  }
  pincode(url: any, data: any): Observable<any> {
    return this.httpService
      .post(url, data)
      .map((res: Response) => res)
      .catch((error: any) => {
        return this.errorHandler(error);
      });
  }
  filterLead(url: any, data: any): Observable<any> {
    return this.httpService
      .ihspost(url, data)
      .map((res: Response) => res)
      .catch((error: any) => {
        return this.errorHandler(error);
      });
  }

  usersByRole(url: any, data: any): Observable<any> {
    return this.httpService
      .post(url, data)
      .map((res: Response) => res)
      .catch((error: any) => {
        return this.errorHandler(error);
      });
  }

  rejectLead(query, data) {
    return this.httpService
      .ihsput(query, data)
      .map((res: Response) => res)
      .catch((error: any) => {
        return this.errorHandler(error);
      });
  }
  reAssignLead(query, data) {
    return this.httpService
      .ihsput(query, data)
      .map((res: Response) => res)
      .catch((error: any) => {
        return this.errorHandler(error);
      });
  }
  getAde(query, data): Observable<any> {
    return this.httpService
      .post(query, data)
      .map((res: Response) => res)
      .catch((error: any) => {
        return this.errorHandler(error);
      });
  }
  getPrimaryBusiness(id) {
    let primary = this.primaryBusines.find((ele) => ele.id == id);
    return { primary_business: primary["primary_business"], id: primary["id"] };
  }
  errorHandler(error: any) {
    this.errService.handleError(error);
    return throwError(error.error);
  }
  getLocation(id) {
    let primary = this.location.find((ele) => ele.id == id);
    return primary["location"];
  }
}
