import "rxjs/add/operator/toPromise";

import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Subject } from "rxjs/Subject";
import { HttpService } from "./utils";

@Injectable()
export class AppService {
  public moduleData = new Subject<any>();
  constructor(private http: HttpService, private router: Router) {}

  setModules(modules: any): void {
    this.moduleData.next(modules);
  }
  getModules(): any {
    return this.moduleData;
  }
}
