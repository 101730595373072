import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { Router } from "@angular/router";
import { SessionStorage } from "ngx-webstorage";
import { faBars, faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { log } from "console";
import { myInsertRemoveTrigger } from "../../utils/animations/animations";
import { MatSnackBar } from "@angular/material/snack-bar";
@Component({
  // tslint:disable-next-line:component-selector
  selector: "amazon-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  animations: [myInsertRemoveTrigger],
})
export class HeaderComponent implements OnInit {
  faBars = faBars;

  collapse = false;

  @ViewChild("toggleButton", { static: true }) toggleButton: ElementRef;
  @ViewChild("menu") menu: ElementRef;

  collapsee() {
    this.collapse = false;
    console.log("hh");
  }

  receive($event: boolean) {
    this.collapsed = $event;
    console.log(this.collapsed);
  }
  @SessionStorage("auth") public user;
  public name: string;

  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    private renderer: Renderer2
  ) {
    this.renderer.listen("window", "click", (e: Event) => {
      console.log(e.target);
      console.log(this.toggleButton);
      console.log(this.menu);
      if (
        e.target !== this.toggleButton.nativeElement &&
        e.target !== this.menu.nativeElement
      ) {
        this.collapsed = false;
      }
    });
  }
  collapsed = false;
  toggleCollapse(): void {
    this.collapsed = !this.collapsed;
    console.log("h entered");
    console.log(this.collapsed);
  }
  ngOnInit() {
    this.name =
      this.user && this.user.userDetails ? this.user.userDetails.name : "";
    if (
      this.user &&
      this.user.userDetails &&
      this.user.userDetails.userid === ""
    ) {
      this.router.navigate([""]);
    }
  }

  logout() {
    this.snackBar.open(
      "Logged out successfully",
      "okay",
      window["snackBarConfig"]
    );
    this.router.navigate([""]);
  }
}
