export class Stores {
    public storeid: string;
    public storename: string;
    public stationcode: string;
    public city: string;
    public createddate: string;
    public checked: boolean;
    public imageMandate: boolean;
    public deleted: boolean;
    public map_ade: string
    public map_ctl: string
    public map_crm: string
    public map_cdm: string
    public map_zm: string
    public priority:boolean;
    public storelocation: Location;
    public xptcode: string;
    public xptlocation: Location;
    map_tm: any;
    map_manager: any;
    map_xptManagers: any;
    xptname: any;
    xptid: number;
    constructor(d: any = {}) {
        this.storeid = d.storeid || '';
        this.xptid=d.xptid || '';
        this.storename = d.storename || '';
        this.xptname=  d.xptname || ''
        this.stationcode = d.stationcode || '';
        this.city = d.city || '';
        this.createddate = d.createddate || '';
        this.checked = false;
        this.deleted = d.deleted||false;
        this.imageMandate = d.imageMandate||false;
        this.map_ade = d.map_ade || '';
        this.map_ctl = d.map_ctl || '';
        this.map_crm = d.map_crm || '';
        this.map_cdm = d.map_cdm || '';
        this.map_zm = d.map_zm || '';
        this.priority=d.priority;        
        this.map_tm = d.map_tm || '';
        this.map_xptManagers = d.map_xptManagers || '';
        this.xptcode = d.xptcode || '';
        this.storelocation = d.storelocation ? new Location(d.storelocation) : new Location();
        this.xptlocation = d.xptlocation ? new Location(d.xptlocation) : new Location();
    }
}







export class myStores {
    public storeid: string;
    public storename: string;
    public stationcode: string;
    public city: string;
    public createddate: string;
    public checked: boolean;
    public imageMandate: boolean;
    public images:any;
    public egyptImages:any;
    public deleted: boolean;
    public map_ade: string
    public map_ctl: string
    public map_crm: string
    public map_cdm: string
    public map_zm: string
    public priority:boolean;
    public storelocation: Location;
    public xptcode: string;
    public xptlocation: Location;
    public imageUpdatedDate:any;
    public imageUpdatedBy:any;
    map_tm: any;
    map_manager: any;
    map_xptManagers: any;
    xptname: any;
    xptid: number;
    constructor(d: any = {}) {
        this.storeid = d.storeid || '';
        this.xptid=d.xptid || '';
        this.storename = d.storename || '';
        this.xptname=  d.xptname || ''
        this.stationcode = d.stationcode || '';
        this.city = d.city || '';
        this.createddate = d.createddate || '';
        this.checked = false;
        this.deleted = d.deleted === true ? false : true;
        this.imageMandate = d.imageMandate === true ? false : true;
        this.map_ade = d.map_ade || '';
        this.map_ctl = d.map_ctl || '';
        this.map_crm = d.map_crm || '';
        this.map_cdm = d.map_cdm || '';
        this.map_zm = d.map_zm || '';
        this.images=d.images||[];
        this.egyptImages=d.egyptImages||[];
        this.imageUpdatedBy=d.imageUpdatedBy;
        this.imageUpdatedDate=d.imageUpdatedDate;
        this.priority=d.priority;        
        this.map_tm = d.map_tm || '';
        this.map_xptManagers = d.map_xptManagers || '';
        this.xptcode = d.xptcode || '';
        this.storelocation = d.storelocation ? new Location(d.storelocation) : new Location();
        this.xptlocation = d.xptlocation ? new Location(d.xptlocation) : new Location();
    }
}
export class GetDateRange {
    public startdate: string;
    public enddate: string;
    public sort = { createddate: -1};
    constructor(d: any = {}) {
        this.startdate = d.startdate || Date.now();
        this.enddate = d.enddate || Date.now();
    }
}
export class DateRangeOptions {
    public options: any;
    constructor() {
        this.options = {
            locale: { format: 'YYYY-MM-DD' },
            alwaysShowCalendars: false
        }
    }
}
export class Stations {
    public stationcode: string;
    public stationlocation: Location
    public type: string;
    public city: string;
    public CRM: string;
    public CDM: string;
    public ZM: string;
    public SM: string;
    public VM: string;
    public Driver: string;
    public TM: string;
    constructor(d: any = {}) {
        this.stationcode = d.stationcode || '';
        this.stationlocation = d.stationcodelocation ? new Location(d.stationcodelocation) : new Location();
        this.type = d.Type || 'No type found';
        this.city = d.city || '';
        this.CRM = d.CRM || '';
        this.CDM = d.CDM || '';
        this.ZM = d.ZM || '';
        this.SM = d.SM || '';
        this.VM = d.VM || '';
        this.Driver = d.Driver || '';
        this.TM = d.TM || '';
    }
}
export class DeleteStore {
    public storeid: Array<string> = [];
    constructor(d: any = {}) {
        this.storeid = d.storeid || [];
    }
}
export class Location {
    // public default:Array<any>=[];
    public coordinates: Array<number> = [];
    public type: string;
    constructor(d: any = {}) {
        // d.default.length > 0 ? this.default = d.default : this.default =[];
        // if (d.coordinates) {
        //     d.coordinates.length > 0 ? this.coordinates = d.coordinates : this.coordinates = [0.000000, 0.000000];
        // } else {
        //     this.coordinates = [0.000000, 0.000000];
        // }
        this.coordinates = (d.hasOwnProperty('coordinates') && d.coordinates.length > 0) ? d.coordinates : [0.000000, 0.000000];
        this.type = d.type || '';
    }
}
