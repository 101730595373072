import { Component, OnInit, Inject } from "@angular/core";
import { ModalService } from "../modal.service";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-assignlead",
  templateUrl: "./assignlead.component.html",
  styleUrls: ["./assignlead.component.scss"],
})
export class AssignleadComponent implements OnInit {
  public role = { roles: ["ADE"] };
  public adeUsers = new Array();
  public reassignForm: UntypedFormGroup;
  public reassignData = new Object();
  public adeList = [];
  constructor(
    public dialogRef: MatDialogRef<AssignleadComponent>,
    public modalService: ModalService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public fb: UntypedFormBuilder,
    public snackBar: MatSnackBar
  ) {
    this.reassignForm = this.fb.group({
      userid: ["", [Validators.required]],
    });
  }

  ngOnInit(): void {
    console.log(this.data, "check data");
    this.reassignData["leads"] = Object.keys(this.data.reAssigned);
    this.reassignData["role"] = "ADE";
    this.getAdeData();
  }
  getAdeData() {
    this.modalService.getAde(`users/by/roles`, this.role).subscribe(
      (response) => {
        response.payload.users.map((ele) => {
          this.adeUsers.push(ele);
          let data = { userid: ele.userid };
          this.adeList = [...this.adeList, data];
        });
      },
      (err) => {}
    );
  }
  public customSearchFn(word: string, item): boolean {
    return item.userid.toLocaleLowerCase() == word.toLocaleLowerCase();
  }
  onUserSelect(id) {
    console.log(id);
    let selectedUserpPincode = this.adeUsers.filter((ele) =>
      ele.userid == id.userid ? ele.pincode : ""
    );
    this.reassignData["pincodes"] = selectedUserpPincode;
    this.reassignData["userid"] = id.userid;
    console.log(this.reassignData, "=>>>>>>>>>>>>");
  }
  closeDialog() {
    this.dialogRef.close();
  }
  ReassignLead() {
    console.log(this.reassignData, "check");
    this.modalService
      .reAssignLead(`lead/reassign`, this.reassignData)
      .subscribe(
        (response) => {
          this.snackBar.open("lead Reassigned sucessfully", "okay", {
            duration: 2000,
            verticalPosition: "top",
            horizontalPosition: "center",
          });
          this.dialogRef.close("reassigned");
        },
        (err) => {
          this.snackBar.open("error occured", "okay", {
            duration: 2000,
            verticalPosition: "top",
            horizontalPosition: "center",
          });
        }
      );
  }
}
