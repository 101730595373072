import { BrowserModule } from "@angular/platform-browser";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import "hammerjs";

/*  For Hanldling Local Storage
 *  Link: https://github.com/PillowPillow/ng2-webstorage
 */
import { NgxWebstorageModule } from "ngx-webstorage";
import { RouterModule } from "@angular/router";

/*  For Hanldling Translations
 *  Link: https://github.com/ngx-translate/core
 */
// import { TranslationModule } from 'angular-l10n';
import { routing } from "./app.routing";

import { UtilsModule } from "./utils/utils.module";
import { AppCommonModule } from "./common/common.module";
import { AppComponent } from "./app.component";

// Common module to start on the start of the application
import { CommonModule } from "@angular/common";

// Services
import { AppService } from "./app.service";
import { StoresService } from "./wow/store/stores.service";
import { SharedService } from "./utils/services/shared.service";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgxWebstorageModule.forRoot(),
    UtilsModule,
    // TranslationModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,

    CommonModule,
    AppCommonModule,
    routing,
    RouterModule,
  ],
  providers: [AppService, StoresService, SharedService],
  bootstrap: [AppComponent],
})
export class AppModule {}
