// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  padding: 20px;
  color: black;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
}

h1,
h2,
h3,
h5,
h6 {
  font-weight: 600;
  margin: 2rem 0;
}

h1 {
  font-size: 30px;
}

h2 {
  font-size: 28px;
}

h3 {
  font-size: 24px;
}

h5 {
  font-size: 20px;
}

h6 {
  font-size: 18px;
}`, "",{"version":3,"sources":["webpack://./src/app/wow/privacy-policy/privacy-policy.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,YAAA;EACA,kCAAA;EACA,eAAA;AACJ;;AAEA;;;;;EAKI,gBAAA;EACA,cAAA;AACJ;;AAEA;EACI,eAAA;AACJ;;AAEA;EACI,eAAA;AACJ;;AAEA;EACI,eAAA;AACJ;;AAEA;EACI,eAAA;AACJ;;AAEA;EACI,eAAA;AACJ","sourcesContent":[".container {\n    padding: 20px;\n    color: black;\n    font-family: \"Poppins\", sans-serif;\n    font-size: 16px;\n}\n\nh1,\nh2,\nh3,\nh5,\nh6 {\n    font-weight: 600;\n    margin: 2rem 0;\n}\n\nh1 {\n    font-size: 30px;\n}\n\nh2 {\n    font-size: 28px;\n}\n\nh3 {\n    font-size: 24px;\n}\n\nh5 {\n    font-size: 20px;\n}\n\nh6 {\n    font-size: 18px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
