import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'safeHtml'
})
export class SafeHtmlPipe implements PipeTransform {

  constructor(private sanitized: DomSanitizer) {}
  
  transform(value: any, args?: any) {
    switch(args){
      case 'html': return this.sanitized.bypassSecurityTrustHtml(value);
      case 'url': return this.sanitized.bypassSecurityTrustUrl(value);
			case 'resourceUrl': return this.sanitized.bypassSecurityTrustResourceUrl(value);
      default: throw new Error(`Invalid safe type specified: ${args}`);
      // default: return this.sanitized.bypassSecurityTrustHtml(value);
    }
  }

}
