import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class ErrorService {

  constructor(
    private router: Router
  ) { }

  handleError(error: any) {
    if (error.status === 401) {
      this.router.navigate(['']);
    }
  }
}
