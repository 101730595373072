import { Component, OnInit, Inject } from "@angular/core";
import { ViewLeadComponent } from "../view-lead/view-lead.component";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  UntypedFormArray,
  UntypedFormControl,
} from "@angular/forms";
import { ModalService } from "../modal.service";
import { Observable } from "rxjs";
import { SharedService } from "../../services/shared.service";
import { DatePipe } from "@angular/common";
import moment from "moment";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-filter-lead",
  templateUrl: "./filter-lead.component.html",
  styleUrls: ["./filter-lead.component.scss"],
  providers: [DatePipe],
})
export class FilterLeadComponent implements OnInit {
  public filterLeadForm: UntypedFormGroup;
  public selectedCar: number;
  public clearFilter = false;
  public toggle = true;
  public isSubmitted = false;
  public options = [];
  public leadAgeData = [];
  public channelTeamUsers = [];
  public users = [];
  public checked = false;
  public AdeTeamUsers = [];
  public filteredOptions: Observable<any>;
  public tools: any;
  public maxDate = new Date();

  leadAge = [
    { days: "1 Day", id: "1", checked: null },
    { days: "2 Days", id: "2", checked: null },
    { days: "3 Days", id: "3", checked: null },
    { days: "4 Days", id: "4", checked: null },
    { days: "5 Days", id: "5", checked: null },
    { days: "> 5 Days", id: ">5", checked: null },
    { days: "10 Days", id: "10", checked: null },
    { days: "< 10 Days", id: "<10", checked: null },
    { days: "> 10 Days", id: ">10", checked: null },
  ];
  totalUsers: any = null;
  constructor(
    public dialogRef: MatDialogRef<ViewLeadComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public fb: UntypedFormBuilder,
    public modalService: ModalService,
    public snackBar: MatSnackBar,
    public sharedService: SharedService,
    public datePipe: DatePipe
  ) {
    this.filterLeadForm = this.fb.group({
      fromDate: null,
      toDate: null,
      days: [],
      cntlTeam: [""],
      leadAge: new UntypedFormArray([]),
      user: [""],
      pincode: [""],
    });
    let today = moment();
    this.tools = [
      {
        id: 0,
        class: "week-action",
        click: "This Week",
        fromDate: today.startOf("week").format("YYYY-MM-DD"),
        toDate: today.endOf("week").format("YYYY-MM-DD"),
      },
      {
        id: 1,
        class: "week-action ",
        click: "Last Month",
        fromDate: moment()
          .subtract(1, "months")
          .startOf("month")
          .format("YYYY-MM-DD"),
        toDate: moment()
          .subtract(1, "months")
          .endOf("month")
          .format("YYYY-MM-DD"),
      },
      {
        id: 2,
        class: "week-action ",
        click: "3 Months",
        fromDate: moment()
          .subtract(3, "months")
          .startOf("month")
          .format("YYYY-MM-DD"),
        toDate: moment()
          .subtract(1, "months")
          .endOf("month")
          .format("YYYY-MM-DD"),
      },
      {
        id: 3,
        class: "week-action ",
        click: "6 Months",
        fromDate: moment()
          .subtract(6, "months")
          .startOf("month")
          .format("YYYY-MM-DD"),
        toDate: moment()
          .subtract(1, "months")
          .endOf("month")
          .format("YYYY-MM-DD"),
      },
      {
        id: 4,
        class: "week-action ",
        click: "Current Year",
        fromDate: moment().startOf("year").format("YYYY-MM-DD"),
        toDate: moment().endOf("year").format("YYYY-MM-DD"),
      },
      {
        id: 5,
        class: "week-action ",
        click: "Last Year",
        fromDate: moment()
          .subtract(1, "year")
          .startOf("year")
          .format("YYYY-MM-DD"),
        toDate: moment().subtract(1, "year").endOf("year").format("YYYY-MM-DD"),
      },
    ];

    console.log(this.tools);
  }

  ngOnInit(): void {
    this.filterLeadForm.valueChanges.subscribe((data) => {
      this.isSubmitted = true;
    });
    console.log(this.data, "check data");
    if (this.data.user) {
      this.filterLeadForm.patchValue(this.data.user);
      if (this.data.user.leadAge) {
        let s = [];
        this.data.user.leadAge.map((ele) => {
          this.leadAge.filter((element) => {
            if (element.id == ele) {
              element["checked"] = ele;
              const formArray: UntypedFormArray = this.filterLeadForm.get(
                "leadAge"
              ) as UntypedFormArray;
              formArray.push(new UntypedFormControl(ele));
              return;
            }
          });
        });
        console.log(s);
      }
    }
    this.getUsers();
  }
  applyDatePickerFilteredLeads(event) {
    this.filterLeadForm.patchValue({
      fromDate: this.datePipe.transform(
        event.picker.startDate._d,
        "yyyy-MM-dd"
      ),
    });
    this.filterLeadForm.patchValue({
      toDate: this.datePipe.transform(event.picker.endDate._d, "yyyy-MM-dd"),
    });
    let data = this.filterLeadForm.value;
    data["status"] = this.data.status;
    data["toDate"] = this.datePipe.transform(data.toDate, "yyyy-MM-dd");
    data["fromDate"] = this.datePipe.transform(data.fromDate, "yyyy-MM-dd");
    this.gelFilterdLeads(data);
  }

  gelFilterdLeads(value) {
    console.log(value);
    if (
      this.filterLeadForm.value.fromDate != null ||
      this.filterLeadForm.value.leadAge.length ||
      this.filterLeadForm.value.pincode ||
      this.filterLeadForm.value.ctl ||
      this.filterLeadForm.value.ade
    ) {
      this.sharedService.display(true);
      this.modalService.filterLead("lead/list", value).subscribe(
        (response) => {
          if (response.success === true) {
          }
          this.totalUsers = response.payload.totalRecords;
          this.sharedService.display(false);
        },
        (err) => {
          this.totalUsers = 0;
          this.sharedService.display(false);
          console.log(err, "");
          this.snackBar.open(err.error.error.message, "okay", {
            duration: 2000,
            verticalPosition: "top",
            horizontalPosition: "center",
          });
        }
      );
    } else {
      this.totalUsers = 0;
    }
  }
  getUsers() {
    let data = {
      roles: ["CTL", "ADE"],
      groupBy: "role",
    };
    this.sharedService.display(true);
    this.modalService.usersByRole("users/by/roles", data).subscribe(
      (response) => {
        if (response.success === true) {
          response.payload.users["CTL"].map(
            (ele) =>
              (this.users = [...this.users, { userid: `CTL-${ele.userid}` }])
          );
          response.payload.users["ADE"].map(
            (ele) =>
              (this.users = [...this.users, { userid: `ADE-${ele.userid}` }])
          );
        }
        this.sharedService.display(false);
      },
      (err) => {
        this.sharedService.display(false);
        console.log(err, "");
        this.snackBar.open(err.error.message, "okay", {
          duration: 2000,
          verticalPosition: "top",
          horizontalPosition: "center",
        });
      }
    );
  }
  getPincodeList(searchText?) {
    this.options = [];
    this.sharedService.display(true);
    this.modalService
      .pincode("pincodes", { pincode: searchText ? searchText : null })
      .subscribe(
        (response) => {
          this.sharedService.display(false);
          if (response.success) {
            this.options = [];
            response.payload.pincodes.map((ele) => {
              this.options = [...this.options, { pincode: ele }];
            });
            console.log(this.options, "===>");
            // this.sharedService.display(false);
          } else {
            this.showError(response, "Error while fetching pincode details.");
          }
        },
        (err) => {
          this.showError(err, "Error while fetching pincoide details.");
          this.sharedService.display(false);
        }
      );
  }
  enableDisableRule() {
    this.toggle = !this.toggle;
  }
  clearUser() {
    let data = this.filterLeadForm.value;
    data.user = [];
    if (data.user.length > 0) {
      let ade = [];
      data.user.map((ele) => {
        if (ele.userid.includes("ADE")) {
          ade.push(ele.userid.split("-")[1]);
        }
      });
      let ctl = [];
      data.user.map((ele) => {
        if (ele.userid.includes("CTL")) {
          ctl.push(ele.userid.split("-")[1]);
        }
      });
      data["ctl"] = ctl;
      data["ade"] = ade;
    }
    // delete data['user']
    data["status"] = this.data.status;
    data["toDate"] = this.datePipe.transform(data.toDate, "yyyy-MM-dd");
    data["fromDate"] = this.datePipe.transform(data.fromDate, "yyyy-MM-dd");
    this.gelFilterdLeads(data);
  }
  removeUser(e) {
    console.log(e.value);
    let data = this.filterLeadForm.value;
    if (data.user.length > 0) {
      let ade = [];
      data.user.map((ele) => {
        if (ele.userid.includes("ADE")) {
          ade.push(ele.userid.split("-")[1]);
        }
      });
      let ctl = [];
      data.user.map((ele) => {
        if (ele.userid.includes("CTL")) {
          ctl.push(ele.userid.split("-")[1]);
        }
      });
      data["ctl"] = ctl;
      data["ade"] = ade;
    }
    // delete data['user']
    data["status"] = this.data.status;
    data["toDate"] = this.datePipe.transform(data.toDate, "yyyy-MM-dd");
    data["fromDate"] = this.datePipe.transform(data.fromDate, "yyyy-MM-dd");
    this.gelFilterdLeads(data);
  }
  removePincode(e) {
    console.log(e.value.pincode);
    let data = this.filterLeadForm.value;
    data.pincode = this.filterLeadForm.value.pincode.filter(
      (ele) => ele != e.value.pincode
    );
    if (data.pincode.length == 0) data.pincode = "";
    if (data.user.length > 0) {
      let ade = [];
      data.user.map((ele) => {
        if (ele.userid.includes("ADE")) {
          ade.push(ele.userid.split("-")[1]);
        }
      });
      let ctl = [];
      data.user.map((ele) => {
        if (ele.userid.includes("CTL")) {
          ctl.push(ele.userid.split("-")[1]);
        }
      });
      data["ctl"] = ctl;
      data["ade"] = ade;
    }
    // delete data['user']
    data["status"] = this.data.status;
    data["toDate"] = this.datePipe.transform(data.toDate, "yyyy-MM-dd");
    data["fromDate"] = this.datePipe.transform(data.fromDate, "yyyy-MM-dd");
    this.gelFilterdLeads(data);
  }
  clearPincode() {
    let data = this.filterLeadForm.value;
    data.pincode = "";
    if (data.user.length > 0) {
      let ade = [];
      data.user.map((ele) => {
        if (ele.userid.includes("ADE")) {
          ade.push(ele.userid.split("-")[1]);
        }
      });
      let ctl = [];
      data.user.map((ele) => {
        if (ele.userid.includes("CTL")) {
          ctl.push(ele.userid.split("-")[1]);
        }
      });
      data["ctl"] = ctl;
      data["ade"] = ade;
    }
    // delete data['user']
    data["status"] = this.data.status;
    data["toDate"] = this.datePipe.transform(data.toDate, "yyyy-MM-dd");
    data["fromDate"] = this.datePipe.transform(data.fromDate, "yyyy-MM-dd");
    this.gelFilterdLeads(data);
  }
  closeDialog() {
    if (
      this.filterLeadForm.value.fromDate != null ||
      this.filterLeadForm.value.leadAge ||
      this.filterLeadForm.value.pincode ||
      this.filterLeadForm.value.ctl ||
      this.filterLeadForm.value.ade
    ) {
      if (this.filterLeadForm.value.pincode.length == 0)
        this.filterLeadForm.value.pincode = "";
      this.dialogRef.close(this.filterLeadForm.value);
    } else if (this.clearFilter) {
      this.dialogRef.close(this.filterLeadForm.value);
    } else {
      this.dialogRef.close();
    }
  }
  clearFilters() {
    console.log(this.leadAge);
    this.tools.map((ele) => (ele.class = "week-action"));
    // this.leadAge.map(ele => ele.checked == null);
    for (let i = 0; i < this.leadAge.length; i++) {
      this.leadAge[i].checked = false;
    }
    this.filterLeadForm.reset();
    const arr = this.filterLeadForm.controls["leadAge"] as UntypedFormArray;
    while (0 !== arr.length) {
      arr.removeAt(0);
    }
    this.totalUsers = 0;
    this.clearFilter = true;
  }
  handleClick(tool) {
    console.log(tool);

    this.filterLeadForm.patchValue({ fromDate: tool.fromDate });
    this.filterLeadForm.patchValue({ toDate: tool.toDate });
    let data = this.filterLeadForm.value;
    data["status"] = this.data.status;
    data["toDate"] = this.datePipe.transform(data.toDate, "yyyy-MM-dd");
    data["fromDate"] = this.datePipe.transform(data.fromDate, "yyyy-MM-dd");
    this.tools.map((ele) =>
      ele.id === tool.id
        ? (ele.class = "create-action")
        : (ele.class = "week-action")
    );
    this.gelFilterdLeads(data);
  }
  showOptions(e, item) {
    const formArray: UntypedFormArray = this.filterLeadForm.get(
      "leadAge"
    ) as UntypedFormArray;
    if (e.checked) {
      // for (let i = 0; i < this.leadAge.length; i++) {
      // 	this.leadAge[item.id - 1].checked = true;
      // }
      this.leadAge.map((ele) => {
        if (ele.id == item.id) {
          ele.checked = true;
        }
      });
      formArray.push(new UntypedFormControl(item.id));
      let data = this.filterLeadForm.value;
      data["status"] = this.data.status;
      data["toDate"] = this.datePipe.transform(data.toDate, "yyyy-MM-dd");
      data["fromDate"] = this.datePipe.transform(data.fromDate, "yyyy-MM-dd");
      this.gelFilterdLeads(data);
    } else {
      let i: number = 0;
      formArray.controls.forEach((ctrl: UntypedFormControl) => {
        if (ctrl.value == item.id) {
          this.leadAge.map((ele) => {
            if (ele.id == item.id) {
              ele.checked = false;
            }
          });
          // Remove the unselected element from the arrayForm
          formArray.removeAt(i);
          let data = this.filterLeadForm.value;
          data["status"] = this.data.status;
          data["toDate"] = this.datePipe.transform(data.toDate, "yyyy-MM-dd");
          data["fromDate"] = this.datePipe.transform(
            data.fromDate,
            "yyyy-MM-dd"
          );
          this.gelFilterdLeads(this.filterLeadForm.value);
          return;
        }
        i++;
      });
    }
  }
  userSelection(e) {
    console.log(e, "=>>>>>>>>>>");
    if (e.length >= 1) {
      let ade = [];
      e.map((ele) => {
        if (ele.userid.includes("ADE")) {
          ade.push(ele.userid.split("-")[1]);
        }
      });
      let ctl = [];
      e.map((ele) => {
        if (ele.userid.includes("CTL")) {
          ctl.push(ele.userid.split("-")[1]);
        }
      });
      console.log(ade, ctl);
      let data = this.filterLeadForm.value;
      // delete data['user'];
      data["ctl"] = ctl;
      data["ade"] = ade;
      data["status"] = this.data.status;
      data["toDate"] = this.datePipe.transform(data.toDate, "yyyy-MM-dd");
      data["fromDate"] = this.datePipe.transform(data.fromDate, "yyyy-MM-dd");
      this.gelFilterdLeads(data);
    }
  }
  pincodeSelection(e) {
    if (e.length >= 1) {
      let data = this.filterLeadForm.value;
      data["status"] = this.data.status;
      data["toDate"] = this.datePipe.transform(data.toDate, "yyyy-MM-dd");
      data["fromDate"] = this.datePipe.transform(data.fromDate, "yyyy-MM-dd");
      this.gelFilterdLeads(this.filterLeadForm.value);
    }
  }

  /* Show the error in snackbar */
  showError(error, statusMessage) {
    this.snackBar.open(
      error.error && error.error.message
        ? error.error.message
        : `${statusMessage}`,
      "okay",
      { duration: 2000, verticalPosition: "top", horizontalPosition: "center" }
    );
  }
  pincodeSearch(e) {
    if (e.target.value.length <= 2) return;
    this.getPincodeList(e.target.value);
  }
  chaneelTeamSearch(e) {
    if (e.target.value.length <= 2) return;
    this.getPincodeList(e.target.value);
  }

  startChange(event) {
    if (
      this.filterLeadForm.get("fromDate").value != null &&
      this.filterLeadForm.get("toDate").value != null
    ) {
      let data = this.filterLeadForm.value;
      delete data["user"];
      data["status"] = this.data.status;
      data["toDate"] = this.datePipe.transform(data.toDate, "yyyy-MM-dd");
      data["fromDate"] = this.datePipe.transform(data.fromDate, "yyyy-MM-dd");
      this.gelFilterdLeads(data);
    }
  }
  endChange(event) {
    if (
      this.filterLeadForm.get("fromDate").value != null &&
      this.filterLeadForm.get("toDate").value != null
    ) {
      let data = this.filterLeadForm.value;
      delete data["user"];
      data["status"] = this.data.status;
      data["toDate"] = this.datePipe.transform(data.toDate, "yyyy-MM-dd");
      data["fromDate"] = this.datePipe.transform(data.fromDate, "yyyy-MM-dd");
      this.gelFilterdLeads(data);
    }
  }
  radioChange(event) {
    this.filterLeadForm.get("leadAge").patchValue(event.value);
    let data = this.filterLeadForm.value;
    data["status"] = this.data.status;
    data["toDate"] = this.datePipe.transform(data.toDate, "yyyy-MM-dd");
    data["fromDate"] = this.datePipe.transform(data.fromDate, "yyyy-MM-dd");
    this.gelFilterdLeads(data);
  }

  displayFn(user): string {
    return user && user.pincode ? user.pincode : "";
  }
}
