import { BehaviorSubject, ReplaySubject, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import "rxjs/add/operator/catch";
import "rxjs/add/operator/map";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Observable";
import { HttpService } from "../../utils/services/http.service";
import { ErrorService } from "../../utils/services/error.service";

@Injectable()
export class LeadsService {
  private btnStatus = new BehaviorSubject<any>({});
  public preservePageData = new PreservePageData();
  private searchStatus = new BehaviorSubject<any>({});
  private resetSearch = new BehaviorSubject<any>({});
  private statusCount = new BehaviorSubject<any>({});
  private uploadStatus = new ReplaySubject<any>();

  constructor(
    private httpService: HttpService,
    private errService: ErrorService
  ) {}

  createLead(url: any, data: any): Observable<any> {
    return this.httpService
      .ihspost(url, data)
      .map((res: Response) => res)
      .catch((error: any) => {
        return this.errorHandler(error);
      });
  }
  getLeads(query): Observable<any> {
    return this.httpService
      .ihsget(query)
      .map((res: Response) => res)
      .catch((error: any) => {
        return this.errorHandler(error);
      });
  }

  assignLeads(query, data) {
    return this.httpService
      .ihsput(query, data)
      .map((res: Response) => res)
      .catch((error: any) => {
        return this.errorHandler(error);
      });
  }
  updateLead(query, data) {
    return this.httpService
      .ihsput(query, data)
      .map((res: Response) => res)
      .catch((error: any) => {
        return this.errorHandler(error);
      });
  }
  upload(url: any, data: any): Observable<any> {
    return this.httpService
      .upload(url, data)
      .map((res: Response) => res)
      .catch((error: any) => {
        return this.errorHandler(error);
      });
  }

  deleteLead(query): Observable<any> {
    return this.httpService
      .ihsdelete(query)
      .map((res: Response) => res)
      .catch((error: any) => {
        return this.errorHandler(error);
      });
  }

  downloadData(query): Observable<any> {
    return this.httpService
      .ihsget(query)
      .map((res: Response) => res)
      .catch((error: any) => {
        return this.errorHandler(error);
      });
  }
  downloadWithData(query, data): Observable<any> {
    return this.httpService
      .ihspost(query, data)
      .map((res: Response) => res)
      .catch((error: any) => {
        return this.errorHandler(error);
      });
  }
  uploadCsvData(query, data) {
    return this.httpService
      .ihspost(query, data)
      .map((res: Response) => res)
      .catch((error: any) => {
        return this.errorHandler(error);
      });
  }
  // upload(url: any, data: any): Observable<any> {
  //   return this.httpService.upload(url, data, 'ihsUrl')
  //     .pipe(
  //       map((res: Response) => {
  //         return res;
  //       }),
  //       catchError(error => {
  //         this.errorHandler(error);
  //         return throwError(error.json());
  //       })
  //     );
  // }
  getDropdownsData(query): Observable<any> {
    return this.httpService
      .ihsget(query)
      .map((res: Response) => res)
      .catch((error: any) => {
        return this.errorHandler(error);
      });
  }

  errorHandler(error: any) {
    this.errService.handleError(error);
    return throwError(error.error);
  }
  get(query): Observable<any> {
    return this.httpService
      .ihsget(query)
      .map((res: Response) => res)
      .catch((error: any) => {
        return this.errorHandler(error);
      });
  }

  post(url: any, data: any): Observable<any> {
    return this.httpService
      .ihspost(url, data)
      .map((res: Response) => res)
      .catch((error: any) => {
        return this.errorHandler(error);
      });
  }

  // to set the subscription data
  setSubscription(subsName, data) {
    switch (subsName) {
      case "btn-status":
        this.btnStatus.next(data);
        break;
      case "search":
        this.searchStatus.next(data);
        break;
      case "resetSearch":
        this.resetSearch.next(data);
        break;
    }
  }
  setStatusCount(data) {
    this.statusCount.next(data);
  }
  getStatusCount() {
    let subscription: any;
    subscription = this.statusCount.asObservable();
    return subscription;
  }
  setUploadStatus(data) {
    this.uploadStatus.next(data);
  }
  getUploadStatus() {
    let subscription: any;
    subscription = this.uploadStatus.asObservable();
    return subscription;
  }
  // to fetch the subscription data
  getSubscription(subsName) {
    let subscription: any;
    switch (subsName) {
      case "btn-status":
        subscription = this.btnStatus.asObservable();
        break;
      case "search":
        subscription = this.searchStatus.asObservable();
        break;
      case "resetSearch":
        subscription = this.resetSearch.asObservable();
        break;
    }
    return subscription;
  }
}
export class PreservePageData {
  public pageNumber: number;
  public searchText: string;
  public pageSize: number;

  constructor(d: any = {}) {
    this.pageNumber = d.pageNumber || 0;
    this.searchText = d.searchText || "";
    this.pageSize = d.hasOwnProperty("pageSize") ? d.pageSize : 10;
  }
}
