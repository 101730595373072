import { Component, OnInit } from "@angular/core";
import { AppCommonService } from "../app-common.service";
import { Router } from "@angular/router";
import { SessionStorage } from "ngx-webstorage";
import { MatSnackBar } from "@angular/material/snack-bar";
@Component({
  selector: "amazon-forgotpassword",
  templateUrl: "./forgotpassword.component.html",
  styleUrls: ["./forgotpassword.component.scss"],
})
export class ForgotpasswordComponent implements OnInit {
  public userid: string;

  @SessionStorage("auth") public user: any;

  constructor(
    private commonService: AppCommonService,
    private snackBar: MatSnackBar,
    private router: Router
  ) {}

  ngOnInit() {}

  forgotPassword() {
    // this.router.navigate(["login"]);
    let forgotPsw = {
      userid: this.userid,
      // 'region':this.user.userDetails.region
    };
    this.commonService.login("forgotpassword", forgotPsw).subscribe(
      (response) => {
        if (response.success) {
          this.snackBar.open(
            "Change password link sent your mail",
            "okay",
            window["snackBarConfig"]
          );
          this.router.navigate(["/login"]);
          // this.userid = '';
        } else {
          this.snackBar.open(
            response.error && response.error.message
              ? response.error.message
              : window["serverError"],
            "okay",
            window["snackBarConfig"]
          );
        }
      },
      (er) => {
        this.snackBar.open(
          er.error && er.error.message && typeof er.error.message === "string"
            ? er.error.message
            : "User ID doesn't exist",
          "okay",
          window["snackBarConfig"]
        );
      }
    );
  }
}
