import { Component, OnInit, Inject } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ModalService } from "../modal.service";
import { SharedService } from "../../services/shared.service";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-rejectlead",
  templateUrl: "./rejectlead.component.html",
  styleUrls: ["./rejectlead.component.scss"],
})
export class RejectleadComponent implements OnInit {
  objectKeys = Object.keys;

  public items = [];
  public RejectForm: UntypedFormGroup;
  public rejectData = { reason: [] };
  public reason = new Object();
  constructor(
    public dialogRef: MatDialogRef<RejectleadComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public fb: UntypedFormBuilder,
    public modalService: ModalService,
    public snackBar: MatSnackBar,
    public sharedService: SharedService
  ) {
    this.RejectForm = this.fb.group({
      reason: ["", [Validators.required]],
      reason2: ["", [Validators.required]],
      reason3: ["", [Validators.required]],
      reason4: ["", [Validators.required]],
    });
  }

  ngOnInit(): void {
    if (this.data.rejected.leadIdAndPincodes) {
      this.rejectData["leadIds"] = Object.keys(
        this.data.rejected.leadIdAndPincodes
      );
    } else {
      this.rejectData["leadIds"] = [this.data.rejected];
    }
    this.sharedService.display(true);
    this.modalService.getReasons("master/reasons").subscribe(
      (res) => {
        console.log(res);
        this.items = res.payload.reasons;
        console.log(this.items);
        this.sharedService.display(false);
      },
      (err) => {
        console.log(err);
        this.sharedService.display(false);
      }
    );
  }
  closeDialog() {
    this.dialogRef.close();
  }
  confirmReject() {
    this.modalService
      .rejectLead(`lead/update/status/reject`, this.rejectData)
      .subscribe(
        (response) => {
          this.snackBar.open("lead rejected sucessfully", "okay", {
            duration: 2000,
            verticalPosition: "top",
            horizontalPosition: "center",
          });
          this.dialogRef.close("rejected");
        },
        (err) => {
          this.snackBar.open(err.error.message, "okay", {
            duration: 2000,
            verticalPosition: "top",
            horizontalPosition: "center",
          });
        }
      );
  }
  onReasonRSelect(id) {
    this.rejectData["reason"] = id;
  }
  showOptions(e, id) {
    if (e.checked) this.rejectData["reason"].push(id);
    else
      this.rejectData["reason"] = this.rejectData["reason"].filter(
        (ele) => ele != id
      );
    console.log(this.rejectData);
  }
}
