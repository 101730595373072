import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppCommonRoutingModule } from "./app-common-routing.module";
import { AppCommonService } from "./app-common.service";
import { ChangepasswordComponent } from "./changepassword/changepassword.component";
import { FooterComponent } from "./footer/footer.component";
import { ForgotpasswordComponent } from "./forgotpassword/forgotpassword.component";
import { HeaderComponent } from "./header/header.component";
import { LayoutComponent } from "./layout/layout.component";
import { LoginComponent } from "./login/login.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { DeleteUserComponent } from "./delete-user/delete-user.component";
import { MatInputModule } from "@angular/material/input";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatTabsModule } from "@angular/material/tabs";

/* services */
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatInputModule,
    MatSnackBarModule,
    MatTabsModule,
    AppCommonRoutingModule,
    ReactiveFormsModule,
  ],
  declarations: [
    LoginComponent,
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    LayoutComponent,
    ForgotpasswordComponent,
    ChangepasswordComponent,
    DeleteUserComponent,
  ],
  providers: [AppCommonService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [LoginComponent],
})
export class AppCommonModule {}
